<template>
  <q-card
    class="q-mb-md"
  >
    <q-card-section>
      <div class="text-h6">{{ value.name }}</div>
      <q-markdown
        class="text-body1"
      >{{ value.description }}
      </q-markdown>
      <p>
        <q-icon
          left
          name="fas fa-plane-departure"
        />
        <strong>{{ $_.get(value, 'facilities', []).length }}</strong>
        airports
      </p>
    </q-card-section>
    <slot/>
  </q-card>
</template>

<script>
  export default {
    props: {
      value: {
        type: Object,
      },
    },
  }
</script>
