<template>
  <section class="q-pa-md">
    <h1>
      Groups
      <q-btn
        :to="`/${$route.params.locale}/dashboard/group/create`"
        color="primary"
        icon="fas fa-plus"
        label="Add"
      />
    </h1>
    <group-card
      v-for="value of data"
      :key="value.id"
      :value="value"
      class="q-mb-md"
    >
      <q-card-actions>
        <q-btn
          :href="`/${$route.params.locale}/group/${value.slug}`"
          color="primary"
          flat
          icon="fas fa-eye"
          label="View"
          target="_blank"
          type="a"
        />
        <q-btn
          :to="`/${$route.params.locale}/dashboard/group/${value.id}/edit`"
          color="secondary"
          flat
          icon="fas fa-edit"
          label="Edit"
        />
        <q-btn
          :to="`/${$route.params.locale}/dashboard/group/${value.id}/delete`"
          color="negative"
          flat
          icon="fas fa-trash"
          label="Delete"
        />
      </q-card-actions>
    </group-card>
    <router-view/>
  </section>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'
  import GroupCard from 'pages/_locale/group/GroupCard'

  const moduleName = 'group'
  const { mapActions, mapState } = createNamespacedHelpers(
    moduleName,
  )

  export default {
    components: {
      GroupCard,
    },
    computed: {
      ...mapState(
        [
          'data',
          'meta',
        ],
      ),
    },
    meta: {
      title: 'Groups',
    },
    methods: {
      ...mapActions(
        [
          'index',
        ],
      ),
      async search () {
        return await this.index(
          {
            ...this.meta,
            own: true,
          },
        )
      },
    },
    async created () {
      await this.search()
    },
    data () {
      return {
        moduleName,
      }
    },
  }
</script>
